import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import { Map } from "immutable";

import SettingLine from "Components/SettingLine";
import SshKeyForm from "Components/SshKeyForm";
import Heading2 from "Components/styleguide/Heading2";
import EmptyText from "Components/EmptyText";
import AddLink from "Components/AddLink";
import Loading from "Components/Loading";
import SshKeyView from "Components/SshKeyView";
import PageDescription from "Components/PageDescription";
import InfoDialog from "Components/InfoDialog";
import IconHelp from "Icons/IconHelp";

const SshKeyWrapper = styled.div`
  p {
    margin-bottom: 30px;
  }
`;

const InfoLayout = styled.div`
  display: flex;
  align-items: center;
  span:first-of-type {
    width: 50%;
  }
  span:nth-of-type(2) {
    width: 20%;
  }
`;

const AddButtonWrapper = styled.div`
  float: right;
  display: flex;
  align-items: center;
  .info-dialog {
    margin-right: 32px;
  }
`;

class SshKeyListField extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.addNewSshKey = this.addNewSshKey.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.delete = this.delete.bind(this);
    this.close = this.close.bind(this);
    this.view = this.view.bind(this);
    this.state = {
      isModalOpen: false,
      visibleSshKey: null,
      isNew: false
    };
  }

  componentDidMount() {
    this.props.loadSshKeys(this.props.project);

    this.setState(() => ({
      sshKeys: [...this.props.sshKeys.valueSeq().toJS()]
    }));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sshKeys !== this.props.sshKeys) {
      this.setState(() => ({
        sshKeys: [...nextProps.sshKeys.valueSeq().toJS()]
      }));
    }
  }

  onChange(value, field, index) {
    this.setState(prevState => {
      const nextState = prevState;

      nextState.sshKeys[index] = {
        ...(this.state.sshKeys[index] || this.props.sshKeys[index]),
        [field]: value
      };

      return nextState;
    });
  }

  save(sshKey) {
    this.props.addSshKey({
      title: sshKey.title,
      value: sshKey.value
    });
  }

  cancel() {
    if (this.state.isNew) {
      this.setState(prevState => {
        const nextState = prevState;
        nextState.isNew = false;
        nextState.sshKeys.splice(0, 1);
        return nextState;
      });
    }
    this.props.cancel();
  }

  delete() {
    this.props.delete(this.state.sshKey.key_id);
  }

  close() {
    this.setState({
      visibleSshKey: null
    });
  }
  view(sshKey, sshKeyIndex) {
    this.setState({
      visibleSshKey: sshKeyIndex,
      sshKey: sshKey,
      isNew: false
    });
    this.cancel();
  }

  addNewSshKey() {
    if (!this.state.isNew) {
      this.setState(prevState => {
        const nextState = prevState;

        nextState.sshKeys.unshift({});
        this.props.editLine(0, true);
        nextState.visibleSshKey = null;
        nextState.isNew = true;
        return nextState;
      });
    }
  }

  render() {
    const { isLoading = true, intl } = this.props;

    return (
      <SshKeyWrapper>
        <AddButtonWrapper>
          <InfoDialog
            title="Learn more"
            text="Learn more about configuring your SSH Keys"
            linkText="Learn more"
            icon={<IconHelp />}
            align="right"
            to="https://docs.platform.sh/development/ssh.html#using-ssh-keys"
          />
          <AddLink
            id="add-new-ssh-key"
            className="primary"
            onClick={this.addNewSshKey}
            text="Add public key"
          />
        </AddButtonWrapper>
        <Heading2>
          <FormattedMessage id="ssh_keys" />
        </Heading2>
        <PageDescription>
          Add your SSH Keys to your account below to use Git and command shells
          with any Platform.sh environment that your user account is authorized
          to work with.{" "}
        </PageDescription>
        {isLoading && <Loading />}
        {!isLoading &&
          this.state.sshKeys.size === 0 && (
            <EmptyText className="no-ssh-keys-message">
              <FormattedMessage id="no_ssh_keys" />
            </EmptyText>
          )}
        {(this.state.sshKeys || this.props.sshKeys.valueSeq()).map(
          (sshKey, index) => {
            if (this.props.editedLine === index) {
              return (
                <SettingLine
                  key={`${sshKey.title}-${index}-new`}
                  info={
                    <InfoLayout>
                      <span>
                        <strong>Add SSH Key</strong>
                      </span>
                    </InfoLayout>
                  }
                  isOpen={true}
                  noToggle={true}
                >
                  <SshKeyForm
                    key={`${sshKey.title}-${index}-edit`}
                    title={sshKey.title}
                    value={sshKey.value}
                    onSave={this.save}
                    onCancel={this.cancel}
                    errors={this.props.errors}
                    isLoading={this.props.isUpdateLoading}
                  />
                </SettingLine>
              );
            }
            if (this.state.visibleSshKey === index) {
              return (
                <SettingLine
                  key={`${sshKey.title}-${index}-view`}
                  id={`org-sshkey-list-${sshKey.title}`}
                  info={
                    <InfoLayout>
                      <span>
                        <strong>{sshKey.title}</strong>
                      </span>
                      <span>{sshKey.id}</span>
                    </InfoLayout>
                  }
                  openText="View"
                  isOpen={true}
                  onClick={this.close}
                >
                  <SshKeyView
                    value={sshKey.value}
                    title={sshKey.title}
                    showDelete={
                      sshKey.hasPermission && sshKey.hasPermission("#delete")
                    }
                    sshKey={this.state.sshKey}
                    deleteFunc={this.delete}
                    intl={intl}
                  />
                </SettingLine>
              );
            }
            return (
              <div key={`${sshKey.title}-${index}-read`}>
                <SettingLine
                  id={`org-sshkey-list-${sshKey.title}`}
                  info={
                    <InfoLayout>
                      <span>
                        <strong>{sshKey.title}</strong>
                      </span>
                      <span>{sshKey.id}</span>
                    </InfoLayout>
                  }
                  openText="View"
                  isOpen={false}
                  onClick={() => this.view(sshKey, index)}
                />
              </div>
            );
          }
        )}
      </SshKeyWrapper>
    );
  }
}

SshKeyListField.propTypes = {
  loadSshKeys: PropTypes.func,
  onChange: PropTypes.func,
  updateSshkey: PropTypes.func,
  editLine: PropTypes.func,
  delete: PropTypes.func,
  addSshKey: PropTypes.func,
  cancel: PropTypes.func,
  project: PropTypes.object,
  sshKeys: PropTypes.object,
  editedLine: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  isLoading: PropTypes.bool,
  isUpdateLoading: PropTypes.bool,
  errors: PropTypes.object,
  intl: PropTypes.object
};

const mapStateToProps = state => {
  const sshKey = state.userSshKey || new Map();

  return {
    editedLine: sshKey.get("editedLine"),
    errors: sshKey.get("errors", new Map()),
    sshKeys: sshKey.get("data", new Map()),
    isUpdateLoading: sshKey.get("updateLoading"),
    isLoading: sshKey.get("loading")
  };
};

const mapDispatchToProps = dispatch => ({
  loadSshKeys: () =>
    import("Reducers/organization/settings/sshKey").then(reducer =>
      dispatch(reducer.loadSshKeys())
    ),
  editLine: index =>
    import("Reducers/organization/settings/sshKey").then(reducer =>
      dispatch(reducer.editLine(index))
    ),
  delete: sshKey =>
    import("Reducers/organization/settings/sshKey").then(reducer =>
      dispatch(reducer.deleteSshKey(sshKey))
    ),
  cancel: () =>
    import("Reducers/organization/settings/sshKey").then(reducer =>
      dispatch(reducer.cancelAddSshKey())
    ),
  addSshKey: sshKey =>
    import("Reducers/organization/settings/sshKey").then(reducer => {
      return dispatch(reducer.addSshKey(sshKey));
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SshKeyListField));
