import React from "react";
import PropTypes from "prop-types";

const IconHelp = ({ color = "#4a495e" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2391 5.54348C13.9348 6.32609 14.2609 7.3913 14.1087 8.52174C13.8478 10.6087 12.3261 11.3478 10.9783 11.3478C10.913 11.3478 10.8696 11.3478 10.8696 11.3478V11.8696C10.8696 12.3478 10.4783 12.7391 10 12.7391C9.52174 12.7391 9.13043 12.3478 9.13043 11.8696V11.1739C9.13043 10.4565 9.43478 9.6087 10.9783 9.6087C11.8261 9.6087 12.2609 9.17391 12.3696 8.30435C12.413 8.02174 12.4348 7.26087 11.913 6.69565C11.5 6.23913 10.8261 6 9.91304 6C7.95652 6 7.8913 7.28261 7.8913 7.41304C7.8913 7.8913 7.5 8.28261 7.02174 8.28261C6.54348 8.28261 6.15217 7.8913 6.15217 7.41304C6.15217 6.54348 6.82609 4.26087 9.91304 4.26087C11.7174 4.26087 12.7174 4.95652 13.2391 5.54348ZM9.93478 13.4783C9.65217 13.4783 9.36957 13.587 9.17391 13.8043C8.97826 14 8.84783 14.2826 8.84783 14.5652C8.84783 14.8478 8.95652 15.1304 9.17391 15.3261C9.36957 15.5217 9.65217 15.6522 9.93478 15.6522C10.2174 15.6522 10.5 15.5435 10.6957 15.3261C10.8913 15.1304 11.0217 14.8478 11.0217 14.5652C11.0217 14.2826 10.913 14 10.6957 13.8043C10.5 13.6087 10.2391 13.4783 9.93478 13.4783ZM20 10C20 15.5217 15.5217 20 10 20C4.47826 20 0 15.5217 0 10C0 4.47826 4.47826 0 10 0C15.5217 0 20 4.47826 20 10ZM18.2609 10C18.2609 5.43478 14.5652 1.73913 10 1.73913C5.43478 1.73913 1.73913 5.43478 1.73913 10C1.73913 14.5652 5.43478 18.2609 10 18.2609C14.5652 18.2609 18.2609 14.5652 18.2609 10Z"
      fill={color}
    />
  </svg>
);

IconHelp.propTypes = {
  color: PropTypes.string
};

export default IconHelp;
