import React from "react";
import PropTypes from "prop-types";

import ContentLayout from "Components/ContentLayout";
import SshKeyListField from "Containers/SshKeyListField";

class OrganizationSshKeys extends React.Component {
  render() {
    const { organizationId } = this.props.params;
    return (
      <ContentLayout id="settings-members" className="settings">
        <SshKeyListField organizationId={organizationId} />
      </ContentLayout>
    );
  }
}

OrganizationSshKeys.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    organizationId: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};
export default OrganizationSshKeys;
