import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "./Button";
import CopyableArea from "Components/CopyableArea";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import { injectIntl } from "react-intl";
import InputField from "Components/fields/InputField";

const SshKeyViewLayout = styled.div`
  padding: 32px;
  min-height: 200px;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
  button.outline {
    position: absolute;
    right: 32px;
    bottom: 32px;
  }
`;

const Layout = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
`;

class SshKeyView extends React.Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      isModalOpen: false
    };
  }

  confirmDelete() {
    this.setState({
      isModalOpen: true
    });
  }

  openModal() {
    this.setState({
      isModalOpen: true
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  render() {
    let { sshKey, intl, deleteFunc } = this.props;
    return (
      <Layout>
        <SshKeyViewLayout>
          <InputField label="Title" value={sshKey.title} isDisabled={true} />
          <div className="row">
            <CopyableArea id={`ssh-key-${sshKey.id}`} content={sshKey.value}>
              {sshKey.value}
            </CopyableArea>
            <Button
              className="outline"
              id={`organization-sshkey-list-${sshKey.id}-delete-btn`}
              onClick={() => this.confirmDelete()}
            >
              {intl.formatMessage({ id: "delete" })}
            </Button>
          </div>
          <ModalConfirmDelete
            isOpen={this.state.isModalOpen}
            closeModal={this.closeModal}
            deleteFunction={deleteFunc}
            itemType="SSH key"
            itemName={sshKey && sshKey.title}
            itemId={sshKey && sshKey.title}
          />
        </SshKeyViewLayout>
      </Layout>
    );
  }
}

SshKeyView.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  deleteFunc: PropTypes.func,
  intl: PropTypes.object,
  sshKey: PropTypes.object,
  close: PropTypes.func
};

export default injectIntl(SshKeyView);
